<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            公告新闻
        </p>
        <div class="contain_notice">
            <div class="contain_notice_body">
                <p v-html="newsInfo.content"></p>
                <!-- <div class="contain_notice_option">
          <div class="option_btn" @click="getnewDetail(Number(id) - 1)">
            <span>上一篇</span>
            <img src="../assets/images/40.png" alt="">
          </div>
          <div class="option_btn" @click="getnewDetail(Number(id) + 1)">
            <span>下一篇</span>
            <img src="../assets/images/40.png" alt="">
          </div>
        </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getNewsDetail } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',
            id: '', //新闻Id
            newsInfo: '', //新闻详情
        };
    },
    computed: {},
    watch: {},
    created () {
        console.log('first', this.$route.query.id)
        this.id = this.$route.query.id;
        this.getBannerList(); //获取banner图
        this.getnewDetail(this.id); //获取新闻详情

    },
    mounted () { },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(3);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },
        //获取新闻详情
        async getnewDetail (id) {
            if (Number(id) < 0) {
                return
            }
            let res = await getNewsDetail(id);
            if (res.code == 200) {
                this.newsInfo = res.data;
            }
        },

    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_notice {
        display: flex;
        justify-content: center;
        padding: 121px 160px 100px;
        background-color: #fff;

        &_body {
            width: 800px;

            padding: 68px 50px;
            border-radius: 20px;
            /*background: #FFF8ED;*/
        }

        &_option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 160px;

            .option_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 134px;
                height: 40px;
                border-radius: 535px;
                background: #FCA429;
                font-size: 16px;
                color: #FFFFFF;
                cursor: pointer;


                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>