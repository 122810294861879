<template>
  <div class="container">

    <div class="contain_top">
      <div class="contain_top_item" v-for="(item, index) in footerList" :key="index">
        <div class="contain_top_item_title" @click="goRouter(item.url)">{{ item.title }}</div>
        <div class="contain_top_item_content" v-for="(son, index2) in item.childen" :key="index2"
          @click="goRouter(son.url)">{{ son.title }}</div>
      </div>
      <!-- <div class="contain_body_ma">
        <img class="ma_img" src="@/assets/images/ma.png" alt="" />
        <p>扫码关注公众号</p>
      </div> -->
    </div>

<!--      <div class="contain_foot">
          <div class="contain_foot_item" width="50%" v-for="(item, index) in bottomList" :key="index">
              <div class="contain_foot_item_title" width="50%"  @click="goWindow(item.bottomUrl)">{{ item.title }}</div>
          </div>
      </div>-->
    <div class="contain_foot">
      <div class="foot_bottom" @click="goSuGongWang()">
        <img class="foot_bottom_img" src="@/assets/images/hui.png" alt="" />
        <p>苏公网安备32010502010740号|</p>
      </div>
        <div class="foot_bottom" @click="goGongxin()">苏ICP备11046409号-8|</div>
        <div class="foot_bottom" @click="goRouter('/license')">支付许可证</div>
    </div>
  </div>
</template>

<script>
import { getForOus } from '@/api/index'
export default {
  name: "foot",
  components: {},
  props: {},
  data () {
    return {
        // baseUrl: baseUrl,
        // bottomList:[
        //     {
        //         title: '苏ICP备11046409号-8|',
        //         bottomUrl: 'http://beian.miit.gov.cn?site=brandsite&from=footer',
        //     },
        //     {
        //         title: '苏公网安备32010502010740号|',
        //         bottomUrl: 'https://beian.mps.gov.cn/#/query/webSearch',
        //     },
        //     {
        //         title: '支付许可证',
        //         bottomUrl: '/license',
        //     },
        // ],
      footerList: [
        {
          title: '关于我们',
          url: '/about',
          childen: [
            {
              title: '公司简介',
              url: '/about'
            }, {
              title: '发展历程',
              url: '/about'
            },
          ]
        }, {
          title: '产品中心',
          url: '/product',
          childen: [
            {
              title: '互联网支付服务',
              url: '/cont'
            }, {
              title: '账户体系服务',
              url: '/account'
            },
          ]
        }, {
          title: '服务接入',
          url: '/join',
          childen: [
            {
              title: '收费标准',
              url: '/charge'
            }, {
              title: '接入流程',
              url: '/access'
            },
              {
                  title: '合作协议',
                  url: '/cooperation'
              },
              {
                  title: '用户协议',
                  url: '/userAgreement'
              }
          ]
        }, {
          title: '客服中心',
          url: '/customer',
          childen: [
            {
              title: '常见问题',
              url: '/customer'
            }, {
              title: '注册与登录',
              url: '/customer'
            }, {
              title: '消费者权益保护',
              url: '/customer'
            }
          ]
        }, {
          title: '联系我们',
          url: '',
          childen: [
            {
              title: '客服热线：400-828-8577',
              url: ''
            }, {
              title: '客服邮箱：csreason@95epay.cn',
              url: ''
            }, {
              title: '公司总部地址：江苏省南京市建邺区贤坤路60号3幢',
              url: ''
            }
          ]
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created () {
    this.getUs(); //获取公司信息
  },
  mounted () { },
  methods: {
    //获取公司信息
    async getUs () {
      let res = await getForOus();
      if (res.code == 200) {
        res.data.map(item => {
          if (item.valueName == 'phone') {
            this.footerList[4].childen[0].title = item.label + '：' + item.values
          }
          if (item.valueName == 'email') {
            this.footerList[4].childen[1].title = item.label + '：' + item.values
          }
          if (item.valueName == 'address') {
            this.footerList[4].childen[2].title = item.label + '：' + item.values
          }
          // if (item.valueName == 'licenseImg') {
          //     this.bottomList[2].bottomUrl = item.values
          // }
        })
      }
    },
    goRouter (url) {
      if (url) {
        this.$router.push({ path: url });
      }
    },
      // goWindow(bottomUrl) {
      //     if (bottomUrl) {
      //         window.location.href = bottomUrl;
      //     }
      // },
    goGongxin () {
      window.location.href = "http://beian.miit.gov.cn?site=brandsite&from=footer";
    },
    goSuGongWang () {
      window.location.href = "https://beian.mps.gov.cn/#/query/webSearch?code=32010502010740";
    },
  },
};
</script>
<style scoped lang='scss'>
.container {
  font-family: "MyCustomFont1";

  .contain_top {
    display: flex;
    padding: 71px 160px 50px;
    justify-content: center;
    background: #444444;

    &_item {
      margin-right: 100px;

      &:last-child {
        margin-right: 0;
      }

      &_title {
        margin-bottom: 19px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }

      &_content {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 100;
        color: #D7D7D7;
        cursor: pointer;
      }
    }

    .contain_body_ma {
      text-align: center;
      font-size: 20px;
      color: #ffffff;

      .ma_img {
        width: 176px;
        height: 176px;
        margin-bottom: 20px;
      }
    }

  }

  .contain_foot {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    justify-content: center; /* 水平居中 */
    padding: 22px 0 26px;
    text-align: center;

    font-size: 12px;
    color: #d7d7d7;
    background: #313131;

    div {
      cursor: pointer;
    }

    .foot_bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;

      &_img {
        width: 14px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
}
</style>