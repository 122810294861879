<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            接入流程
        </p>

        <div class="contain_access">
            <div class="contain_access_item" v-for="(item, index) in accessList" :key="index">
                <div class="contain_access_item_img">
                    <!-- <img :src="item.img" alt="" /> -->
                    <img src="@/assets/images/49.png" alt="" />

                    <span>step{{ index + 1 }}</span>
                </div>

                <div>{{ item.content }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getMerchantAccess } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',

            accessList: [
                {
                    content: '开通流程：您可以直接拨打乐在支付客服热线:4008288577，我司将为您提供一对一顾咨询服务;'
                }, {
                    content: '资料审核：您需要提供如下资料至乐在支付，其中基础资料包括营业执照、开户许可证或基本户信息、法人身份证正反面照片、客户开通表（统一模板）、开户意愿书（统一模板）；辅助资料包括、公司办公场地租赁合同、包含门头的开户视频与照片、受益所有人证件、企业征信报告等;'
                }, {
                    content: '签订《乐在支付产品合作协议》;'
                }, {
                    content: '接口对接：支付账户开通好后，乐在支付将建立对接群发送接口文档，客户根据文档内容和乐在支付技术进行接口对接调试、直至正式使用。'
                },
            ]
        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList(); //获取banner图
    },
    mounted () { },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(3);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },
    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_access {
        background: url("../assets/images/back.png") no-repeat;
        background-size: cover;
        width: 100%;
        padding: 123px 221px;

        &_item {
            margin-bottom: 80px;
            font-size: 22px;
            font-family: "MyCustomFont3";

            &_img {
                position: relative;

                img {
                    width: 109px;
                    height: 59px;
                    margin-bottom: 30px;
                }

                span {
                    position: absolute;
                    top: 24px;
                    left: 34px;
                    font-size: 20px;
                    color: #FFFFFF;
                }
            }


        }
    }
}
</style>