import axios from "axios";

export const baseUrl = 'https://gwadmin.lzpay.com/'//基础请求地址
// export const baseUrl = 'http://localhost:18081'//基础请求地址

let request = axios.create({
    baseURL: baseUrl,
    timeout: 5000//超时的时间设置
})
// 2. request实例添加请求与响应拦截器
request.interceptors.request.use((config) => {
    // config配置对象，headers属性请求头，经常给服务器端携带公共参数
    // 返回配置对象
    return config
})

request.interceptors.response.use((response) => {
    //成功回调
    return response.data
}, (error) => {
    // 失败回调
    // 定义一个变量：存储网络错误信息
    let message = '';
    let status = error.response.status;
    switch (status) {
        case 401:
            message = "TOKEN过期"
            break;
        case 403:
            message = "无权访问"
            break;
        case 404:
            message = "请求地址错误"
            break;
        case 500:
            message = "服务器出现问题"
            break;
        default:
            message = "网络出现问题"
            break;

    }
    // 提示错误信息
    // ElMessage({
    //     type: 'error',
    //     message
    // })
    return Promise.reject(message);
})

// 对外暴露
export default request;