<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            产品中心
        </p>
        <div class="contain_service">
            <p class="service_title">优质服务</p>
            <div class="service_box">
                <div class="service_box_item" v-for="(item, index) in serviceList" :key="index" @click="goRouter(item)">
                    <img class="service_box_item_img" :src="baseUrl + item.img" alt="" />
                    <img class="service_box_item_img2" :src="baseUrl + item.icon" alt="" />
                    <p>{{ item.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getProduct } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',
            serviceList: []
        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList(); //获取banner图
        this.getServiceList(); //获取优质服务
    },
    mounted () { },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(2);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },
        //获取优质服务
        async getServiceList () {
            let res = await getProduct();
            if (res.code == 200) {
                this.serviceList = res.data;
            }
        },
        // 跳转
        goRouter (item) {
            let url
            if (item.id == 1) {
                url = '/cont'
            } else {
                url = '/account'
            }
            this.$router.push({ path: url });
        },
    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_service {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 100px 0;

        .service_title {
            margin-bottom: 50px;
            text-align: center;
            font-weight: 600;
            font-size: 28px;
            color: #333333;
            font-family: "MyCustomFont1";

        }

        .service_box {
            display: flex;

            &_item {
                width: 550px;
                height: 640px;
                margin-left: 40px;
                background-color: #fff;
                font-family: "MyCustomFont2";

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                    z-index: 2;
                }

                &_img {
                    width: 100%;
                    height: 336px;
                    border-radius: 20px;
                }

                &_img2 {
                    width: 86px;
                    height: 86px;
                    padding: 52px 50px;
                }

                p {
                    padding-left: 50px;
                    font-weight: 600;
                    font-size: 28px;
                    color: #000000;
                    text-decoration: none;
                }
            }
        }
    }
}
</style>