<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            服务接入
        </p>
        <div class="contain_first">
            <div class="first_box" v-for="(item, index) in navList" :key="index" @click="goRouter(item)">
                <img class="first_box_img" :src="baseUrl + item.icon" alt="" />
                <p>{{ item.type }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getMerchantAccess } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',
            navList: [],
        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList(); //获取banner图
        this.getNavList(); //获取商户接入模块
    },
    mounted () { },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(3);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },
        //获取商户接入模块
        async getNavList () {
            let res = await getMerchantAccess();
            if (res.code == 200) {
                this.navList = res.data;
            }
        },

        goRouter (item) {
            let url
            if (item.id == 1) {
                url = '/charge'; //收费标准
            } else if (item.id == 2) {
                url = '/access'; //接入流程
            } else if (item.id == 3) {
                url = '/cooperation'; //合作协议
            }else if (item.id == 4) {
                url = '/userAgreement'; //用户协议
            }

            if (url) {
                this.$router.push({ path: url });
            }
        },
    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_first {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 162px 0;

        .first_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 350px;
            height: 350px;
            border-radius: 24px;
            background-color: #fff;
            font-weight: 600;
            font-size: 24px;
            color: #000000;

            &:hover {
                transform: scale(1.05);
                transition: transform 0.3s ease;
                z-index: 2;
            }

            &_img {
                width: 100px;
                height: 100px;
                margin-bottom: 33px;
            }

            &:nth-child(2) {
                margin: 0 50px;
            }
            &:nth-child(4) {
                margin: 0 50px;
            }
        }
    }
}
</style>