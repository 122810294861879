<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            关于我们
        </p>
        <div class="contain_jianjie">
            <div class="jianjie_title">公司简介</div>
            <div class="jianjie_body">
                <div class="jianjie_body_l">
                    <p class="jianjie_body_l_title">
                        {{ CompanyInfo.title }}
                    </p>
                    <p class="jianjie_body_l_content">
                        {{ CompanyInfo.content }}
                    </p>
                </div>
                <div class="jianjie_img">
                    <img :src="baseUrl + CompanyInfo.img" alt="" />
                </div>
            </div>
        </div>

        <div class="contain_develop">
            <p class="develop_title">发展历程</p>
            <div class="develop_body">
                <div class="develop_body_l">
                    <img v-if="developInfo.eventImg" :src="baseUrl + developInfo.eventImg.values" alt="" />
                </div>
                <div class="develop_body_r">
                    <div style="overflow: hidden">
                        <div class="develop_body_r_item" v-for="(item, index) in developInfo.lzEvents" :key="index">
                            <p class="develop_body_r_item_date">
                                {{ dealDate(item.eventTime) }}
                            </p>
                            <div class="develop_body_r_item_biao"></div>
                            <p class="develop_body_r_item_content">
                                {{ item.event }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getForOusCompany, getForOusEvent } from '@/api/index';
import { baseUrl } from '@/api/service';

export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',
            CompanyInfo: {}, //公司简介
            developInfo: {}, //发展历程
        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList(); //获取banner图
        this.getCompanyInfo(); //获取公司简介
        this.getDeveloplist(); //获取发展历程
    },
    mounted () { },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(5);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },

        //获取公司简介
        async getCompanyInfo () {
            let res = await getForOusCompany();
            if (res.code == 200) {
                this.CompanyInfo = res.data;
            }
        },
        //获取发展历程
        async getDeveloplist () {
            let res = await getForOusEvent();
            if (res.code == 200) {
                this.developInfo = res.data;
            }
        },
        // 处理时间
        dealDate (v) {
            if (v) {
                let y = v.split("-").slice(0, 1);
                let m = v.split("-").slice(1, 2);
                return y + '年' + m + '月'
            }
        }
    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";

        }
    }

    .contain_jianjie {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        .jianjie_title {
            margin-bottom: 68px;
            font-weight: 600;
            font-size: 28px;
            color: #333333;
            font-family: "MyCustomFont2";
        }

        .jianjie_body {
            display: flex;

            &_l {
                width: 720px;
                height: 450px;
                padding: 42px 46px 18px;
                background-color: #fff;
                overflow-y: auto;
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */

                &::-webkit-scrollbar {
                    /* Chrome, Safari 和 Opera */
                    display: none;
                }

                &_title {
                    margin-bottom: 40px;
                    font-weight: 600;
                    font-size: 24px;
                }

                &_content {
                    font-size: 22px;
                    line-height: 35px;
                    font-family: "MyCustomFont3";

                }
            }

            .jianjie_img {
                width: 600px;
                height: 450px;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                    z-index: 2;
                }
            }
        }
    }

    .contain_develop {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 98px 0;
        padding: 100px 0 148px;
        background-color: #fff;

        .develop_title {
            margin-bottom: 68px;
            text-align: center;
            font-weight: 600;
            font-family: "MyCustomFont2";
            font-size: 35px;
            color: #333333;
            font-family: "AlibabaPuHuiTi-Bold";
        }

        .develop_body {
            display: flex;

            &_l {
                width: 600px;
                height: 450px;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                    z-index: 2;
                }
            }

            &_r {
                width: 720px;
                height: 450px;
                padding: 0 20px 0 51px;
                background: #f7f8fa;
                overflow-y: auto;
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */

                &::-webkit-scrollbar {
                    /* Chrome, Safari 和 Opera */
                    display: none;
                }

                &_item {
                    display: flex;
                    align-items: center;

                    &_date {
                        width: 150px;
                        font-weight: 600;
                        font-size: 22px;
                        color: #bca17c;
                        font-family: "MyCustomFont2";

                    }

                    &_biao {
                        position: relative;
                        margin: 20px;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        border: 1px solid #bca17c;
                        background: #f7f8fa;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background-color: #bca17c;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 18px;
                            left: 8px;
                            width: 2px;
                            height: 3000px;
                            background-color: #d3d3d3;
                        }
                    }

                    &_content {
                        flex: 1;
                        font-size: 20px;
                        font-family: "MyCustomFont3";

                    }
                }

                &_item:last-child {
                    .develop_body_r_item_biao::after {
                        content: "";
                        position: absolute;
                        top: 19px;
                        left: 0px;
                        width: 20px;
                        height: 20px;
                        background-color: #f7f8fa;
                    }
                }
            }
        }
    }
}
</style>