import request from './service.js';

// 获取 公司信息
export const getForOus = () =>  {
    return request({
        url: "/api/getForOus",
        method: "get",
    });
};

// 获取 banner图
export const getHomeBanner = (id) =>  {
    return request({
        url: "/api/getHomeBanner/"+id,
        method: "get",
    });
};

// 获取 首页业务场景
export const getHomeBusiness = () =>  {
    return request({
        url: "/api/getHomeBusiness",
        method: "get",
    });
};

// 获取 首页公告新闻
export const getHomeNews = (params) =>  {
    return request({
        url: "/api/getHomeNews",
        method: "get",
        params
    });
};
// 获取 公告新闻详情
export const getNewsDetail = (id) =>  {
    return request({
        url: "/api/getHomeNews/"+id,
        method: "get",
    });
};


// 获取 产品中心
export const getProduct = () =>  {
    return request({
        url: "/api/getProduct",
        method: "get",
    });
};

// 获取 商户接入
export const getMerchantAccess = () =>  {
    return request({
        url: "/api/getMerchantAccess",
        method: "get",
    });
};

// 获取 公司简介
export const getForOusCompany = () =>  {
    return request({
        url: "/api/getForOusCompany",
        method: "get",
    });
};
// 获取 发展历程
export const getForOusEvent = () =>  {
    return request({
        url: "/api/getForOusEvent",
        method: "get",
    });
};

// 获取 客服中心问题
export const getIssue = () =>  {
    return request({
        url: "/api/getIssue",
        method: "get",
    });
};
// 获取 客服中心答案
export const getIssueAnswer = (id) =>  {
    return request({
        url: "/api/getIssue/"+id,
        method: "get",
    });
};