<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            收费标准
        </p>
        <div class="contain_charge">
            <div class="charge_box">
                <div class="charge_img">
                    <img :src="baseUrl + chargeImg" alt="" />
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import { getHomeBanner, getMerchantAccess } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',
            chargeImg: ''
        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList(); //获取banner图
        this.getNavList(); //获取商户接入模块（收费标准）
    },
    mounted () { },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(3);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },
        //获取商户接入模块（收费标准）
        async getNavList () {
            let res = await getMerchantAccess();
            if (res.code == 200) {
                this.chargeImg = res.data[0].concat;
            }
        },
    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_charge {
        padding: 104px 174px;

        .charge_box {
            .charge_img img {
                height: 2065px;
                margin-left: 20px;
            }
        }
    }
}
</style>