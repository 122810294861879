<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            客服中心
        </p>

        <div class="contain_customer">
            <div class="contain_customer_l">
                <div class="contain_customer_l_item" v-for="(item, index) in customerList" :key="index"
                    @click="selectTitle(index)">
                    <div class="contain_customer_l_item_top" :class="{ active_item_top: customerIndex == index }">
                        <span>{{ item.name }}</span>
                        <span class="contain_customer_l_item_top_arrow"
                            :class="{ active_item_top_arrow: customerIndex == index }"></span>
                    </div>
                    <div v-if="customerIndex == index" class="contain_customer_l_item_content">
                        <div class="content_item" :class="{ active_content_item: customerIndex2 == index2 }"
                            v-for="(question, index2) in item.children" :key="index2"
                            @click="selectContent(question, index2)">
                            {{ question.name }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- ql-editor 解决富文本编辑样式问题的 -->
            <div class="contain_customer_r ql-editor">
                <!-- <div class="contain_customer_r_title">{{ anSwerInfo.issue }}</div> -->
                <p class="contain_customer_r_content" v-html="anSwerInfo.answer"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getIssue, getIssueAnswer } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',

            customerList: [],
            customerIndex: 0,
            customerIndex2: 0,
            anSwerInfo: '', //答案


        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList(); //获取banner图
        this.getCustomerList(); //获取客服中心
    },
    mounted () {

    },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(4);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },
        //获取客服中心问题
        async getCustomerList () {
            let res = await getIssue();
            if (res.code == 200) {
                this.customerList = res.data;
                this.getAnswer(this.customerList[0].children[0].id);
            }
        },

        // 选择一级
        selectTitle (index) {
            if (this.customerIndex != index) {
                this.customerIndex2 = 0;
                this.getAnswer(this.customerList[index].children[0].id)//点击一级呈现二级第一个答案
            }
            this.customerIndex = index;

        },
        // 选择二级
        selectContent (question, index) {
            this.customerIndex2 = index;
            this.getAnswer(question.id); //获取答案
        },
        //获取答案
        async getAnswer (id) {
            let res = await getIssueAnswer(id);
            if (res.code == 200) {
                this.anSwerInfo = res.data;
            }

        }
    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_customer {
        display: flex;
        padding: 121px 160px 100px;

        &_l {
            width: 487px;
            height: 891px;
            padding: 21px 33px 21px 22px;
            background: #FFF8ED;
            border-radius: 20px 20px 20px 20px;
            margin-right: 20px;

            &_item {
                margin-bottom: 20px;
                cursor: pointer;


                &_top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 24px 44px;
                    font-weight: 600;
                    font-size: 20px;
                    color: #333333;
                    background: #fff0d9;
                    border-radius: 20px 20px 20px 20px;
                    font-family: "MyCustomFont1";


                    &_arrow {
                        width: 12px;
                        height: 12px;
                        border-top: 2px solid #333333;
                        border-right: 2px solid #333333;
                        transform: rotate(45deg);
                    }

                    .active_item_top_arrow {
                        border-top: 2px solid #fff;
                        border-right: 2px solid #fff;
                        transform: rotate(135deg);
                    }
                }

                .active_item_top {
                    background-color: #FCA429;
                    color: #fff;
                }

                &_content {
                    padding-left: 44px;

                    .content_item {
                        margin: 29px 0;
                        font-weight: 400;
                        font-size: 18px;
                        color: #666666;
                        font-family: "MyCustomFont3";

                    }

                    .active_content_item {
                        font-weight: 700;
                        font-size: 18px;
                        color: #FCA429;
                    }
                }
            }
        }

        &_r {
            flex: 1;
            height: 891px;
            padding: 47px 79px;
            background: #FFF8ED;
            border-radius: 20px 20px 20px 20px;
            overflow-y: auto;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */

            &::-webkit-scrollbar {
                /* Chrome, Safari 和 Opera */
                display: none;
            }

            &_title {
                padding-bottom: 8px;
                font-size: 20px;
                font-weight: 700;
                font-family: "MyCustomFont1";

            }

            &_content {
                font-size: 20px;
                line-height: 40px;
                color: #3D3D3D;
                font-family: "MyCustomFont3";

            }
        }
    }
}
</style>
