<template>
    <div class="container">
        <div class="switch">
            <el-carousel>
                <el-carousel-item v-for="(item, index) in swiperList" :key="index">
                    <div class="switch_box">
                        <img class="nav_bg" :src="baseUrl + item.img" alt="" />
<!--                        <img class="switch_box_zi" src="@/assets/images/content.png" alt="" />-->
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="contain_first">
            <div class="first_title">
                <img src="@/assets/images/45.png" alt="">
                <span>业务场景</span>
                <img src="@/assets/images/46.png" alt="">
            </div>
            <div class="first_body">
                <div class="first_body_item" v-for="(item, index) in firstList" :key="index">
                    <div class="first_body_item_content">
                        <img :src="baseUrl + item.img" alt="" />
                        <div class="first_body_item_content_bottom">
                            <img src="@/assets/images/48.png" alt="">
                            <span>{{ item.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contain_third">
            <div class="third_title">
                <img src="@/assets/images/45.png" alt="">
                <span>支付场景</span>
                <img src="@/assets/images/46.png" alt="">
            </div>
            <div class="third_body">
                <div class="third_body_item" v-for="(item, index) in secondList" :key="index">
                    <div class="third_body_item_content">
                        <img class="third_body_item_content_bg" src="@/assets/images/28.png" alt="">
                        <div class="item_content">
                            <img :src="baseUrl + item.img" alt="" />
                            <div>{{ item.title }}</div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- <div class="third_bottom">
        <span class="third_bottom_btn">联系我们</span>
      </div> -->
        </div>

        <div class="contain_four">
            <div class="four_title">
                <img src="@/assets/images/45.png" alt="">
                <span>核心优势</span>
                <img src="@/assets/images/46.png" alt="">
            </div>

            <div class="four_body">
                <div class="four_body_item" v-for="(item, index) in thirdList" :key="index">

                    <div class="four_body_item_content">
                        <img class="four_body_item_content_bg" src="@/assets/images/29.png" alt="">
                        <div class="item_content">
                            <div class="item_content_l">
                                <div class="item_content_l_title">{{ item.title }}</div>
                                <div>{{ item.concat }}</div>
                            </div>
                            <div class="item_content_r">
                                <img :src="baseUrl + item.img" alt="">
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="contain_second">
            <div class="second_title">
                <img src="@/assets/images/45.png" alt="">
                <span>公告&新闻</span>
                <img src="@/assets/images/46.png" alt="">
            </div>
            <div class="second_body">
                <div class="second_body_l">
                    <img :src="baseUrl + newsImg" alt="" />
                </div>
                <div class="second_body_r">
                    <div class="new_box" :class="{ new_box_active: index == newIndex }" v-for="(item, index) in newList"
                        :key="index" @click="selectnew(item, index)">
                        <div class="new_box_l">
                            <div class="new_box_l_top">{{ dealDate(item.releaseDate, 1) }}</div>
                            <div class="new_box_l_bottom">{{ dealDate(item.releaseDate, 2) }}</div>
                        </div>
                        <div class="new_box_r">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div class="second_btn" @click="goRouter('/newsList')">查看更多</div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getHomeBusiness, getHomeNews } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            swiperList: [],
            firstList: [], //业务场景
            secondList: [],//全场景通用
            thirdList: [], //核心优势
            newList: [], //新闻公告
            newIndex: 0,
            newsImg: ''
        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList(); //获取轮播图
        this.getFirstList(); //获取业务场景
        this.getNewList(); //获取新闻
    },
    mounted () { },
    methods: {
        //获取轮播图
        async getBannerList () {
            let res = await getHomeBanner(1);
            if (res.code == 200) {
                this.swiperList = res.data;
            }
        },
        //获取业务场景
        async getFirstList () {
            let res = await getHomeBusiness();
            if (res.code == 200) {
                this.firstList = res.data.businesses;
                this.secondList = res.data.businesses2;
                this.thirdList = res.data.businesses3;
            }
        },
        //获取新闻
        async getNewList () {
            let params = {
                pageNum: 1,
                pageSize: 3
            }
            let res = await getHomeNews(params);
            if (res.code == 200) {
                this.newsImg = res.data.img.values;
                this.newList = res.data.newList.list;
            }
        },


        // 选择新闻
        selectnew (item, index) {
            this.newIndex = index;
            console.log('item', item)
            this.$router.push({ path: '/newsDetail', query: { id: item.id } });
        },

        // 处理时间
        dealDate (v, n) {
            if (v) {
                switch (n) {
                    case 1:
                        let d = v.split("-").slice(2, 3);
                        return d + ''
                        break
                    case 2:
                        let y = v.split("-").slice(0, 1);
                        let m = v.split("-").slice(1, 2);

                        return y + '.' + m
                        break
                    default:
                }
            }
        },
        goRouter (url) {
            if (url) {
                this.$router.push({ path: url });
            }
        },
    },
};
</script>
<style>
.switch .el-carousel__container {
    height: 841px;
}

.switch .el-carousel__arrow {
    width: 85px;
    height: 85px;
    border: 1px solid #c0aea3;
    color: #c0aea3;
}

.switch .el-carousel__arrow--left {
    left: 175px;
}

.switch .el-carousel__arrow--right {
    right: 175px;
}

.switch .el-carousel__indicator--horizontal .el-carousel__button {
    width: 18px;
    height: 18px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
}

.switch .el-carousel__indicator--horizontal.is-active .el-carousel__button,
.switch3 .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    background: #fca429;
}
</style>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .switch_box {
        position: relative;
        height: 969px;

        .nav_bg {
            width: 100%;
            height: 100%;
            line-height: 841px;
        }

        &_zi {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 669px;
            height: 53px;
        }
    }

    .contain_first {
        padding: 118px 350px 0;
        background-color: #fff;

        .first_title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 36px;
            font-family: "MyCustomFont1";
            font-size: 28px;
            font-weight: bold;
            color: #2B2B2B;
            cursor: default;

            span {
                padding: 0 40px;
            }

            img {
                width: 144px;
                height: 24px;
            }
        }

        .first_body {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            &_item {
                flex: 0 0 33%;
                margin-bottom: 20px;
                cursor: default;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                }

                &_content {
                    position: relative;

                    width: 378px;
                    height: 258px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &_bottom {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        padding: 22px 0 27px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: rgba(0, 0, 0, 0.4);
                        font-weight: 500;
                        font-size: 16px;
                        font-family: "MyCustomFont3";
                        color: #ffffff;

                        img {
                            width: 24px;
                            height: 20px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    .contain_third {
        padding: 58px 350px 0;
        background-color: #fff;

        .third_title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 36px;
            font-family: "MyCustomFont1";
            font-size: 28px;
            font-weight: bold;
            color: #2B2B2B;
            cursor: default;

            span {
                padding: 0 40px;
            }

            img {
                width: 144px;
                height: 24px;
            }
        }

        .third_body {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            &_item {
                flex: 0 0 33%;
                margin-bottom: 40px;
                cursor: default;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                }

                &_content {
                    position: relative;
                    width: 378px;
                    height: 252px;

                    &_bg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .item_content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 22px;
                        font-weight: bold;
                        font-family: "MyCustomFont3";

                        img {
                            width: 102px;
                            height: 102px;
                            margin-bottom: 20px;
                        }
                    }
                }

            }
        }

        .third_bottom {
            display: flex;
            justify-content: center;

            &_btn {
                position: relative;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                padding: 0 48px 0 22px;
                font-size: 16px;
                border: 1px solid #FCA429;
                border-radius: 535px;
                color: #FCA429;
                font-family: "MyCustomFont3";
                cursor: pointer;

                &::after {
                    content: "";
                    position: absolute;
                    top: 16px;
                    right: 28px;
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid #FCA429;
                    border-right: 1px solid #FCA429;
                    transform: rotate(45deg);
                }
            }
        }
    }

    .contain_four {
        padding: 130px 340px 0;
        background-color: #fff;

        .four_title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 36px;
            font-family: "MyCustomFont1";
            font-size: 28px;
            font-weight: bold;
            color: #2B2B2B;
            cursor: default;

            span {
                padding: 0 40px;
            }

            img {
                width: 144px;
                height: 24px;
            }
        }

        .four_body {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            &_item {
                flex: 0 0 50%;
                margin-bottom: 40px;
                cursor: default;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                }

                &_content {
                    position: relative;
                    width: 586px;
                    height: 200px;

                    &_bg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .item_content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        &_l {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding-left: 63px;
                            font-size: 18px;
                            font-family: "MyCustomFont3";

                            &_title {
                                font-size: 22px;
                                font-weight: bold;
                                font-family: "MyCustomFont1";
                                margin-bottom: 20px;
                            }
                        }

                        &_r {
                            width: 160px;
                            height: 160px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 0 35px 35px 0;
                            }
                        }
                    }
                }
            }
        }

    }

    .contain_second {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 82px 0 74px;
        background-color: #fff;

        .second_title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 36px;
            font-family: "MyCustomFont1";
            font-size: 28px;
            font-weight: bold;
            color: #2B2B2B;
            cursor: default;

            span {
                padding: 0 40px;
            }

            img {
                width: 144px;
                height: 24px;
            }
        }

        .second_body {
            display: flex;

            &_l {
                width: 540px;
                height: 344px;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &_r {
                width: 650px;
                background: #FFF8ED;

                .new_box {
                    display: flex;
                    align-items: center;
                    padding: 19px 0;
                    color: #333333;
                    font-family: "MyCustomFont2";
                    cursor: pointer;

                    &_l {
                        padding: 0 41px;
                        text-align: center;

                        &_top {
                            font-size: 30px;
                        }

                        &_bottom {
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }

                    &_r {
                        padding-right: 41px;
                        font-size: 22px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .new_box_active {
                    background: #FCA429;
                    color: #FFF;
                }

            }
        }

        .second_btn {
            position: relative;
            margin-top: 40px;
            height: 40px;
            line-height: 40px;
            padding: 0 48px 0 22px;
            font-size: 16px;
            border: 1px solid #FCA429;
            border-radius: 535px;
            color: #FCA429;
            font-family: "MyCustomFont3";
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                top: 16px;
                right: 28px;
                width: 6px;
                height: 6px;
                border-top: 1px solid #FCA429;
                border-right: 1px solid #FCA429;
                transform: rotate(45deg);
            }
        }
    }
}
</style>