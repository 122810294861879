<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            互联网支付服务
        </p>

        <div class="contain_cont">
            <div class="cont_l">
                <img :src="baseUrl + serviceInfo.img" alt="" />

            </div>
            <div class="cont_r">
                <div class="cont_r_top">
                    <img class="cont_r_top_img1" :src="baseUrl + serviceInfo.icon" alt="" />
                    <img class="cont_r_top_img2" src="@/assets/images/image.png" alt="" />
                </div>
                <div class="cont_r_title">{{ serviceInfo.name }}</div>
                <div class="cont_r_content">
                    {{ serviceInfo.concat }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getProduct } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',
            serviceInfo: ''
        };
    },
    computed: {},
    watch: {},
    created () {
        this.getBannerList();
        this.getServiceList();
    },
    mounted () { },
    methods: {
        //获取轮播图
        async getBannerList () {
            let res = await getHomeBanner(2);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },


        // 获取互联网支付服务信息
        async getServiceList () {
            let res = await getProduct();
            if (res.code == 200) {
                this.serviceInfo = res.data[0];
            }
        },

    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 190px;
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_cont {
        display: flex;
        justify-content: center;
        padding: 126px 340px;
        ;
        background-color: #fff;

        .cont_l {
            img {
                width: 500px;
                height: 358px;
                margin-right: 20px;
                border-radius: 20px;
            }

            &:hover {
                transform: scale(1.05);
                transition: transform 0.3s ease;
                z-index: 2;
            }
        }

        .cont_r {
            flex: 1;
            height: 358px;
            padding: 50px;
            border-radius: 20px;
            background-color: #fff8ed;


            .cont_r_top {
                display: flex;
                justify-content: space-between;

                &_img1 {
                    width: 70px;
                    height: 70px;
                }

                &_img2 {
                    width: 146px;
                    height: 42px;
                }
            }

            &_title {
                font-size: 28px;
                font-weight: 600;
                margin: 20px 0;
                font-family: "MyCustomFont2";

            }

            &_content {
                font-size: 18px;
                color: #333;
                line-height: 36px;
                font-family: "MyCustomFont3";

            }
        }
    }
}
</style>