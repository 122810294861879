import Vue from 'vue'
import Router from 'vue-router'

import Index from '@/views/index.vue'; //首页
import Product from '@/views/product.vue'; //产品中心
import Cont from '@/views/cont.vue'; //互联网支付服务
import Account from '@/views/account.vue'; //账户体系服务
import Join from '@/views/join.vue'; //商户接入
import Charge from '@/views/charge.vue'; //收费标准
import Access from '@/views/access.vue'; //接入流程
import Cooperation from '@/views/cooperation.vue' //合作协议
import UserAgreement from '@/views/userAgreement.vue' //用户协议
import license from '@/views/license.vue' //证书
import Customer from '@/views/customer.vue' //客服中心
import About from '@/views/about.vue' //关于我们
import NewsList from '@/views/newsList.vue' //新闻公告列表
import NewDetail from '@/views/newsDetail.vue' //新闻公告详情



// 解决跳转相同路由报错问题
// 获取原型对象push函数
const originalPush = Router.prototype.push
// 获取原型对象replace函数
const originalReplace = Router.prototype.replace
// 修改原型对象中的push函数
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
Router.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)


const router = new Router({
  mode: 'hash',
  routes: [
    { path: '/', redirect: '/index' },
    { path: '/index', component: Index },
    { path: '/product', component: Product },
    { path: '/cont', component: Cont },
    { path: '/account', component: Account },
    { path: '/join', component: Join },
    { path: '/charge', component: Charge },
    { path: '/access', component: Access },
    { path: '/cooperation', component: Cooperation },
    { path: '/userAgreement', component: UserAgreement },
    { path: '/license', component: license },
    { path: '/customer', component: Customer },
    { path: '/about', component: About },
    { path: '/newsList', component: NewsList },
    { path: '/newsDetail', component: NewDetail },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }    // y 代表高度为0 
    }
  }
})

export default router