<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + licenseImg" alt=""/>
    </div>
</template>

<script>
import {getForOus} from '@/api/index'
import {baseUrl} from '@/api/service';

export default {
    props: {},
    data() {
        return {
            baseUrl: baseUrl,
            licenseImg: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getUs(); //获取公司信息

    },
    mounted() {
    },
    methods: {
        async getUs () {
            let res = await getForOus();
            if (res.code == 200) {
                res.data.map(item => {
                    if (item.valueName == 'licenseImg') {
                        this.licenseImg = item.values
                    }
                })
            }
        },
    },
};
</script>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 60%;
        height: 60%;
        transform: translateX(30%);
        &_text {
            position: absolute;
            top: 300px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }
}
</style>