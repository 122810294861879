<template>
  <div class="container" :class="{ 'container_active': navBgShow }">
    <div class="log_box">
      <img class="logo_img" src="@/assets/images/image.png" alt="" />
      <div class="log_box_position">
        <img class="logo_img2" src="@/assets/images/logo2.png" alt="" />
        <img class="logo_img3" src="@/assets/images/logo3.png" alt="" />
      </div>
    </div>
    <div class="contain_nav_box">
      <div class="nav_item" v-for="(item, index) in navList" :key="index" @click="goRouter(item.url)">
        <div class="nav_item_title">{{ item.title }}</div>
        <div v-if="item.son.length > 0" class="nav_son">
          <div class="nav_son_box">
            <div class="nav_son_item" v-for="(son, index2) in item.son" :key="index2" @click.stop="goRouter(son.url)">
              {{ son.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="nav_login" @click.stop="goLogin()">登录</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navTab",
  components: {},
  props: {},
  data () {
    return {
      navBgShow: false,
      navList: [
        {
          title: "首页",
          url: "/index",
          son: [],
        },
        {
          title: "产品中心",
          url: "/product",
          son: [],
        },
        {
          title: "服务接入",
          url: "/join",
          son: [
            {
              title: "收费标准",
              url: "/charge",
              // url: "",
            },
            {
              title: "接入流程",
              url: "/access",
              // url: "",
            },
            {
              title: "合作协议",
              url: "/cooperation",
              // url: "",
            },
            {
                title: "用户协议",
                url: "/userAgreement",
            },
          ],
        },
        {
          title: "客服中心",
          url: "/customer",
          son: [],
        },
        {
          title: "关于我们",
          url: "/about",
          son: [],
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created () { },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop
      // 当页面滑动的距离大于300px时元素显示，否则不显示
      if (scrollTop >= 300) {
        this.navBgShow = true
      } else {
        this.navBgShow = false
      }
    },


    goRouter (url) {
      if (url) {
        this.$router.push({ path: url });
      }
    },
    goLogin () {
      window.location.href = "https://my.lzpay.com";
    },
  },
};
</script>
<style scoped lang='scss'>
.container {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  // height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 300px;
  font-family: "MyCustomFont1";
  background: transparent !important;
  z-index: 100;


  .log_box {
    position: relative;

    .logo_img {
      width: 146px;
      height: 42px;
    }

    .log_box_position {
      position: absolute;
      top: 0;
      left: 150px;
      width: 100px;
      height: 100%;
      display: flex;
      flex-direction: column;

      .logo_img2 {
        width: 20px;
        height: 10px;
        margin-bottom: 6px;
      }

      .logo_img3 {
        width: 84px;
        height: 19px;
      }
    }
  }

  .contain_nav_box {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #fff;

    .nav_item {
      position: relative;
      margin-left: 90px;
      cursor: pointer;

      &:hover {
        color: #e4b653;
      }

      .nav_son {
        position: absolute;
        top: 20px;
        left: -50%;
        padding-top: 30px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease, transform 0.3s ease;
        cursor: pointer;

        .nav_son_box {
          width: 150px;
          padding: 15px 0;
          line-height: 50px;
          background: rgba(0, 0, 0, 0.7);
          font-size: 16px;
          color: #c9c9dd;

          .nav_son_item {
            text-align: center;

            &:hover {
              color: #fca429;
            }
          }
        }
      }
    }

    .nav_item:hover .nav_son {
      opacity: 1;
      pointer-events: auto;
    }

    .nav_login {
      padding: 10px 40px;
      margin-left: 90px;
      background: #fca429;
      border-radius: 223px;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.container_active {
  background: rgba(0, 0, 0, 0.8) !important;
}
</style>