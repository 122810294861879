<template>
  <div id="app">
    <navTab />
    <!-- 使用<transition>标签包起来 -->
    <transition name="fade">
      <router-view />
    </transition>
    <foot />
  </div>
</template>

<script>
import navTab from "@/components/navTab.vue";
import foot from "@/components/foot.vue";

export default {
  components: {
    navTab,
    foot
  },
  name: 'app'
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-to {
  display: none;
}
</style>
