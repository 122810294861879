import { render, staticRenderFns } from "./newsList.vue?vue&type=template&id=d57ce99c&scoped=true"
import script from "./newsList.vue?vue&type=script&lang=js"
export * from "./newsList.vue?vue&type=script&lang=js"
import style0 from "./newsList.vue?vue&type=style&index=0&id=d57ce99c&prod&lang=css"
import style1 from "./newsList.vue?vue&type=style&index=1&id=d57ce99c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d57ce99c",
  null
  
)

export default component.exports