<template>
    <div class="container">
        <img class="nav_bg" :src="baseUrl + bannerImg" alt="" />
        <p class="nav_bg_text">
            公告新闻
        </p>
        <div class="contain_news">
            <div class="contain_news_body">
                <div class="contain_news_body_item" v-for="(item, index) in newList" :key="index"
                    @click="goNewDetail(item)">
                    <div class="contain_news_body_item_content">
<!--                        <img :src="baseUrl + item.cover" alt="">-->
                        <div class="content_body">
                            <p class="content_body_title">{{ item.title }}</p>
<!--                            <p class="content_body_text" v-html="$options.filters.ellipsis(item.content)"></p>-->
                            <div class="content_body_bottom">
                                <span >{{ item.releaseDate }}</span>
                                <div class="option_btn">
                                    <span>查看更多</span>
                                    <img src="@/assets/images/47.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="contain_news_page">
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-size="pageSize" layout=" prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getHomeBanner, getHomeNews } from '@/api/index'
import { baseUrl } from '@/api/service';
export default {
    props: {},
    data () {
        return {
            baseUrl: baseUrl,
            bannerImg: '',

            newList: [],
            pageSize: 10, //条数
            pageNum: 1, //页数
            total: 0


        };
    },
    computed: {},
    watch: {},
    filters: {
        //当渲染的文字超出19字后显示省略号
        ellipsis (value) {
            value = value.replace(/<.*?>/ig, "").replace(/\s*/g, "")
            if (value.length > 19) {
                return value.slice(0, 19) + "...";
            } else {
                return value
            }
            ;
        }
    },
    created () {
        this.getBannerList(); //获取banner图
        this.getNewList(1);//获取新闻列表


    },
    mounted () { },
    methods: {
        //获取banner图
        async getBannerList () {
            let res = await getHomeBanner(2);
            if (res.code == 200) {
                this.bannerImg = res.data[0].img;
            }
        },

        // 点击分页  获取新闻列表
        handleCurrentChange (val) {
            this.getNewList(val);
        },

        //获取新闻列表
        async getNewList (val) {
            let params = {
                pageNum: val,
                pageSize: this.pageSize
            }
            let res = await getHomeNews(params);
            if (res.code == 200) {
                this.newList = res.data.newList.list;
                this.total = res.data.newList.totalCount;
            }
        },
        goNewDetail (item) {
            this.$router.push({ path: '/newsDetail', query: { id: item.id } });
        },

        goRouter (url) {
            if (url) {
                this.$router.push({ path: url });
            }
        },
    },
};
</script>
<style>
.el-pagination.is-background .el-pager li {
    background-color: #FFF8ED !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #FCA429 !important;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
    background-color: #FFF8ED !important;
    color: #FCA429 !important;
}
</style>
<style scoped lang='scss'>
.container {
    position: relative;
    background-color: #f7f8fa;

    .nav_bg {
        width: 100%;
        height: 439px;

        &_text {
            position: absolute;
            top: 180px;
            left: 50%;
            transform: translate(-50%);
            font-size: 50px;
            color: #fff;
            font-family: "MyCustomFont1";
            cursor: default;
        }
    }

    .contain_news {
        padding: 152px 160px 112px;
        background-color: #fff;

        &_body {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            flex-direction: column;

            &_item {
                flex: 0 0 25%;
                margin-bottom: 10px;
                /*position: absolute;*/
                /*left: 50%;*/
                transform: translateX(50%);


                &_content {
                    width: 772px;
                    border-radius: 35px;
                    border: 1px solid rgba(252, 164, 41, 0.44);
                    height: 55px;
                    font-family: "MyCustomFont1";

                    img {
                        width: 372px;
                        height: 219px;
                        border-radius: 35px;
                    }

                    .content_body {
                        display: flex;
                        padding: 10px 22px 33px;


                        &_title {
                            font-size: 22px;
                            font-weight: bold;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        &_text {
                            margin: 4px 0 36px;
                            font-size: 16px;
                            color: #666666;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        &_bottom {
                            display: flex;
                            /*text-align: right;*/
                            position: absolute;
                            right: 1%;
                            justify-content: space-between;
                            align-items: center;
                            font-size: 16px;
                            color: #999999;

                            .option_btn {
                                display: flex;
                                margin-left: 10px;
                                justify-content: center;
                                align-items: center;
                                width: 134px;
                                height: 30px;
                                border-radius: 535px;
                                border: 1px solid #FCA429;
                                font-size: 16px;
                                color: #FCA429;
                                cursor: pointer;


                                img {
                                    width: 16px;
                                    height: 16px;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }


                }
            }
        }

        &_page {
            margin-top: 76px;
            text-align: center;
        }
    }
}
</style>